<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">Test - Face Match</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-row justify="center">
      <v-col md="8" sm="10" class="pt-10 text-center">
        <v-file-input
          solo
          label="Image 1"
          show-size
          placeholder="Upload image 1"
          v-model="image1"
        />
        <v-file-input
          solo
          label="Image 2"
          show-size
          placeholder="Upload image 2"
          v-model="image2"
        />
        <v-btn
          color="success"
          :disabled="!(image1 && image2)"
          @click="checkFaceMatch"
          :loading="loading"
        >
          <v-icon>mdi-cloud-upload</v-icon>&nbsp; Test face match
        </v-btn>
      </v-col>
      <v-col v-if="matchData" md="8" sm="12" class="pt-4 text-center">
        <v-card class="my-5">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr v-for="item in items" :key="item.name">
                  <td class="text-right">{{ item.name }}</td>
                  <td class="text-left">
                    {{ item.value(matchData) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col v-else-if="errorData" md="12" class="pt-4 text-center">
        <v-card dark class="my-5 pa-3">
          <div class="overflow-x-auto">
            <pre class="red--text text-left">{{ JSON.stringify(errorData, null, 4) }}</pre>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { FACE_MATCH_CHECK } from "@/constants/api";
import moment from "moment";

export default {
  name: "TestFaceMatch",
  metaInfo: { title: "Test - Face Match" },
  components: {},
  data: () => ({
    loading: false,
    matchData: null,
    errorData: null,
    image1: null,
    image2: null,
    items: [
      { name: "Request Id", value: (item) => item.requestId },
      { name: "Label", value: (item) => item.label },
      { name: "Confidence", value: (item) => Math.round(100 * item.confidence, 2) + "%" },
      { name: "Distance", value: (item) => item.distance },
      { name: "Threshold", value: (item) => item.threshold },
      { name: "Processing Time", value: (item) => item.processingTime },
      {
        name: "Requested At",
        value: (item) => moment(item.createdAt).format("DD MMM, YYYY \n hh:mm:ss A"),
      },
    ],
  }),
  mounted() {
    this.matchData = this.$store?.state?.test?.testMatchData?.matchData;
  },
  methods: {
    showError(err) {
      console.log(err);
      this.$iziToast.error({ title: err || "Something went wrong!" });
    },
    async checkFaceMatch() {
      this.loading = true;
      this.errorData = null;
      this.matchData = this.errorData = null;
      try {
        const data = new FormData();
        data.append("face1", this.image1);
        data.append("face2", this.image2);
        const response = await this.$axios.post(FACE_MATCH_CHECK, data);
        this.matchData = response.data?.data;
        this.$store.commit("test/setTestMatchData", {
          matchData: this.matchData || null,
        });
      } catch (err) {
        this.errorData = err?.response?.data || err?.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
